// See https://dev.to/zigabrencic/analytics-with-vanilla-js-page-views-47pb
function trackEvent(eventName, sessionId, userId, data) {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "/events", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            console.log(xhr.responseText);
        }
    };
    let event = {
        event_name: eventName,
        date_time: new Date(),
        session_id: sessionId,
        user_id: userId,
        data: data,
    };
    xhr.send(JSON.stringify(event));
}

function initVerification(form) {
    console.log('Rendering the Turnstile widget...')

    let verificationPromise = new Promise((resolve, reject) => {
        turnstile.render(form, {
            sitekey: form.dataset.sitekey,
            callback: function (token) {
                console.log(`Challenge Success: ${token}`);
                setTimeout(() => {
                    console.log('Token is supposed to be expired now after 300 seconds...');
                }, 300 * 1000);
                resolve(token);
            },
            'expired-callback': function () {
                console.log('Token expired...');
            }
        });
    });

    form.addEventListener('submit', function (event) {
        event.preventDefault(); // Prevent default form submission

        // Wait for the verification to complete before submitting
        verificationPromise.then(token => {
            this.submit();
        }).catch(error => {
            // Handle errors here
            console.error('Verification process failed: ', error);
        });

        event.submitter.innerText = 'Verifying you are a human...';
    });
}


document.addEventListener('DOMContentLoaded', (event) => {
    var userId = document.documentElement.dataset['userId'];
    var sessionId = document.documentElement.dataset['sessionId'];

    trackEvent(
        "page.viewed",
        sessionId,
        userId,
        {
            url: window.location.href, // location
            referrer: document.referrer,
            user_agent: navigator.userAgent,
            language: navigator.language,
            // TODO add page title, page path, screen size, etc.
        });

    window.onloadTurnstileCallback = function () {
        document.querySelectorAll('.cf-turnstile-form').forEach((form) => {
            initVerification(form);
        })
    };

    document.querySelectorAll('.toggle-button').forEach(button => {
        button.addEventListener('click', function (event) {
            var content = button.parentNode.children[1];
            if (content.classList.contains('md:hidden')) {
                content.classList.remove('md:hidden');
            } else {
                content.classList.add('md:hidden');
            }
            event.stopPropagation();
        })
    });
    document.addEventListener("click", function (event) {
        document.querySelectorAll('.toggle-content').forEach(content => {
            if (event.target.closest('.toggle-content') !== content) {
                if (!content.classList.contains('md:hidden')) {
                    content.classList.add('md:hidden');
                }
            }
        });
    });
})
